* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #282c34;
  padding: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container h1 {
  margin: 10px 0 20px 0;
  color: white;
}
select,
button {
  padding: 10px;
  margin: 5px;
  border: none;
  background-color: #4a7afd;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

h3 {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: white;
  padding: 10px;
}

@media (min-width: 800px) {
  .App {
    display: block;
  }
}
